<template>
  <section id="extra-filters" class="extra-filters">
    <div class="extra-filters__fields">
      <div class="extra-filter" v-if="surveyExtraOptions.length > 0">
        <label for="chart-type">
          {{
            lang.surveyCharts.create.form.filters.type.label[languageSelected]
          }}
        </label>
        <multiselect
          id="chart-type"
          v-model="field"
          :options="surveyExtraOptions"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :placeholder="
            lang.surveyCharts.create.form.filters.type.placeholder[
              languageSelected
            ]
          "
        />
      </div>

      <vs-input
        v-else
        v-model="field"
        label="Enter field"
        :placeholder="
          lang.surveyCharts.create.form.filters.type.label[languageSelected]
        "
        class="extra-filter"
      />
      <!-- :danger="validation.name"
      :danger-text="
        lang.botMaker.addBot.generalSettings.error.name.empty[languageSelected]
      " -->

      <vs-input
        :label="
          lang.surveyCharts.create.form.filters.value.label[languageSelected]
        "
        :placeholder="
          lang.surveyCharts.create.form.filters.value.placeholder[
            languageSelected
          ]
        "
        class="extra-value"
        v-model="value"
        :disabled="!field"
        @keyup.enter="addExtra"
      />

      <vs-button
        radius
        color="primary"
        class="add-extra-btn"
        icon="add"
        @click.stop="addExtra"
        :disabled="!field || !value"
      />
    </div>
    <!-- :danger="validation.name" :danger-text="
    lang.botMaker.addBot.generalSettings.error.name.empty[languageSelected] " -->
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ExtraFilters',
  data() {
    return {
      field: null,
      value: null
    }
  },
  props: {
    surveyExtraOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session'])
  },
  methods: {
    addExtra() {
      if (this.value) {
        const extra = {
          field: this.field,
          value: this.value
        }

        this.$emit('onExtraAdd', extra)
        this.reset()
      }
    },
    reset() {
      this.field = null
      this.value = null
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.extra-filters {
  label {
    font-size: 0.85rem;
    padding-left: 5px;
  }

  &__fields {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    .extra-filter {
      flex-basis: 50%;
      margin-right: 15px;
    }
    .extra-value {
      flex-basis: 50%;
      margin-right: 54px;
    }
    .add-extra-btn {
      position: absolute;
      margin-top: 1.5rem !important;
      right: 0;
    }
  }
}
</style>
